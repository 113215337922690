document.addEventListener("DOMContentLoaded", function(event) {

    const showNavbar = (toggleId, navId, bodyId, headerId) =>{
        const toggle = document.getElementById(toggleId),
            nav = document.getElementById(navId),
            bodypd = document.getElementById(bodyId),
            headerpd = document.getElementById(headerId)

// Validate that all variables exist
        if(toggle && nav && bodypd && headerpd){
            toggle.addEventListener('click', ()=>{
// show navbar
                nav.classList.toggle('show')
// change icon
                toggle.classList.toggle('fa-x')
// add padding to body
                bodypd.classList.toggle('body-pd')
// add padding to header
                headerpd.classList.toggle('body-pd')
            })
        }
    }

    showNavbar('header-toggle','nav-bar','body-pd','header')

    /*===== LINK ACTIVE =====*/
    const linkColor = document.querySelectorAll('.nav_link')

    function colorLink(){
        if(linkColor){
            linkColor.forEach(l=> l.classList.remove('active'))
            this.classList.add('active')
        }
    }
    linkColor.forEach(l=> l.addEventListener('click', colorLink))

    // Your code to run since DOM is loaded and ready

    $("#search_user").on('keyup',function(e){
        let search_text = e.target.value;
        if (search_text === '') {
            $('.user_list').fadeIn();
        } else {
            $(".user_lastname").each(function () {
                if ($(this).is(':contains('+search_text+')')) {
                    $(this).closest('.user_list').fadeIn();
                } else {
                    $(this).closest('.user_list').fadeOut();
                }
            });
        }
    });

    $("#search_company").on('keyup',function(e){
        let search_text = e.target.value;
        if (search_text === '') {
            $('.company_list').fadeIn();
        } else {
            $(".company_name").each(function () {
                if ($(this).is(':contains('+search_text+')')) {
                    $(this).closest('.company_list').fadeIn();
                } else {
                    $(this).closest('.company_list').fadeOut();
                }
            });
        }
    });
});